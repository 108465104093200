import React from 'react'
import drpathaks from '../../Assets/images/drpathakji.jpg'
import restdoctor from '../../Assets/images/drruchi.png'
import drrina from '../../Assets/images/drmam.jpeg'
import {Link} from 'react-router-dom'

const Doctors = () => {
    return (
        <div>
            <div className='container my-5 py-5'>
                <div className="intro">
                    <h2 className="text-center aboutheading">Our Specialists</h2>
                    <h6 className='text-center pt-2 pb-5'> Meet our experienced specialists who are here to assist you with your needs.</h6>
                </div>
                <div className="row people leftspace py-lf-5">
                    <div className="col-md-6 col-lg-4 item">
                        <div className="box">
                            <img className="rounded-circle" src={drpathaks} alt='Dr Raghav Pathak' />
                            <h4 className="name">Dr Raghav Pathak</h4>
                            <p className="title">BHMS DNYS</p>
                            <p className="description">
                            Senior Homoeopathy and Naturopathy Physician Reg.No. 16813
                            </p>
                            <Link to='/ourteam'>
                                <button className="text-decoration-none pathakjibtn font-weight-bold">
                                More Info
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 item">
                        <div className="box">
                            <img className="rounded-circle" src={drrina} alt='Dr Ruchi Pathak' />
                            <h4 className="name">Dr.Rina Mudiya </h4>
                            <p className="title">  BNYS </p>
                            <p className="description">
                            
                            Naturopathy  and yoga physician <br />Reg. No. 57745
                            </p>
                            <Link to='/ourteam'>
                                <button className="text-decoration-none pathakjibtn font-weight-bold">
                                    More Info
                                </button>
                            </Link>                      
                          </div>
                    </div>
                    <div className="col-md-6 col-lg-4 item">
                        <div className="box">
                            <img className="rounded-circle" src={restdoctor} alt='Dr Anshul Pathak' />
                            <h4 className="name">Dr Name</h4>
                            <p className="title">Specialist</p>
                            <p className="description">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In magna
                            </p>
                            <Link to='/ourteam'>
                                <button className="text-decoration-none pathakjibtn font-weight-bold">
                                    More Info
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Doctors