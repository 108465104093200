import { createSlice } from '@reduxjs/toolkit'

const initialState = []

function saveCartToLocalStorage(products) {
  localStorage.setItem('cartItems', JSON.stringify(products));
}

export const productSlice = createSlice({
  name: 'productSliceName',
  initialState,
  reducers: {
    addProduct: (state, { payload }) => {
      console.log('payload', payload)
      const productExist = state.findIndex(item => item.id === payload.id);
      if (productExist !== -1) {
        state[productExist].qty++;
        state[productExist].totalPrice = state[productExist].qty * state[productExist].price;
      } else { state.push(payload) };
      saveCartToLocalStorage(state)
    },
    removeProduct: (state, { payload }) => {
      state = state.filter(item => item.id !== payload.id);
      saveCartToLocalStorage(state)
      return state
    },
    updateProduct: () => {

    },
    emptyCart: (state, action) => {
      state.value += action.payload
    },

    getProduct: ()=>{

    },
    getOneProduct: ()=>{

    },
    success: (state, {payload})=>{
      console.log(payload)
      state = payload
      return state
    },
    failed:()=>{

    }
  },
})

// Action creators are generated for each case reducer function
export const { addProduct, removeProduct, updateProduct, getProduct, success, failed, getOneProduct } = productSlice.actions

export default productSlice.reducer