import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import servicebanner from '../../Assets/images/service.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import at1 from '../../Assets/images/at1.jpeg';
import at2 from '../../Assets/images/at2.jpeg';
import at3 from '../../Assets/images/at3.jpeg';
import mt1 from '../../Assets/images/mt1.jpeg';
import mt2 from '../../Assets/images/mt2.jpeg';
import mt3 from '../../Assets/images/mt3.jpeg';
import vt1 from '../../Assets/images/vt1.jpeg';
import vt2 from '../../Assets/images/vt2.jpeg';
import vt3 from '../../Assets/images/vt3.jpeg';
import ct1 from '../../Assets/images/cmt1.jpeg';
import ct2 from '../../Assets/images/cmt2.jpeg';
import ct3 from '../../Assets/images/cmt3.jpeg';
import st1 from '../../Assets/images/st1.jpeg';
import st2 from '../../Assets/images/st2.jpeg';
import st3 from '../../Assets/images/st3.jpeg';
import vk1 from '../../Assets/images/vt1.jpeg';
import vk2 from '../../Assets/images/vt2.jpeg';
import vk3 from '../../Assets/images/vt3.jpeg';

const Mainservices = () => {
  return (
    <div>
      <div class="border-0">
                <img className="bannerimges img-fluid" src={servicebanner} alt="Card image" />   
      </div>
      <div className="container py-5 my-lg-5">
        <section id="departments" className="departments section">
          <div className="container section-title text-center" data-aos="fade-up">
            <h2 className="text-center aboutheading">Our Services</h2>
            <h6 className="text-center pt-2">
              Explore our range of holistic therapies designed to rejuvenate and heal your body
            </h6>
          </div>
          {/* Abhyangam Therapy */}
          <div className="row py-lg-5 my-lg-5">
            <div className="col-md-6 ">
              <h3 className="serviceheading">Abhyangam Therapy</h3>
              <p className="text-justify servicetext py-4">
                Abhyangam is a traditional Ayurvedic full-body massage that rejuvenates and detoxifies the body, promoting overall health and well-being. Using warm herbal oils and gentle techniques, it balances the doshas, improves circulation, and nourishes tissues, providing a profound sense of relaxation and revitalization. Regular sessions help maintain a youthful appearance and alleviate muscular tension, enhancing both physical and mental wellness.
              </p>
              <div className="mt-4 serviceappoitmentbtn">
                <Link to="/makeappointment" className="text-decoration-none pathakjibtn font-weight-bold">
                  Make an Appointment
                </Link>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-md-5 order-1 order-md-2">
              <Carousel>
                <Carousel.Item>
                  <img src={at1} alt="Abhyangam Therapy" className="serviceimages" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={at2} alt="Abhyangam Therapy" className="serviceimages" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={at3} alt="Abhyangam Therapy" className="serviceimages" />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>


          {/* Marma Therapy */}
          <div className="row py-5 my-lg-5">
            <div className="col-md-6 order-2 order-md-1">
              <Carousel>
                <Carousel.Item>
                  <img src={mt1} alt="Marma Therapy" className="serviceimages" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={mt2} alt="Marma Therapy" className="serviceimages" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={mt3} alt="Marma Therapy" className="serviceimages" />
                </Carousel.Item>
              </Carousel>
            </div>
           
            <div className="col-md-6 order-1 order-md-2" >
              <h3 className="serviceheading">Marma Therapy</h3>
              <p className="text-justify servicetext py-4">
                Marma Therapy involves massaging the body's vital points, enhancing energy flow and promoting healing. This ancient Ayurvedic technique targets specific areas to release blocked energy, relieve pain, and restore balance to the body's natural functions. It is particularly effective in treating chronic pain and improving mental clarity, making it a comprehensive treatment for holistic health.
              </p>
              <div className="mt-4 serviceappoitmentbtn">
                <Link to="/makeappointment" className="text-decoration-none pathakjibtn font-weight-bold">
                  Make An Appointment
                </Link>
              </div>
            </div>
          </div>

          {/* Vibrator Marma Therapy */}
          <div className="row py-lg-5 my-lg-5">
            <div className="col-md-7">
              <h3 className="serviceheading">Vibrator Marma Therapy</h3>
              <p className="text-justify servicetext py-4">
                Vibrator Marma Therapy combines vibration techniques with marma point massage to relieve tension and improve circulation. This specialized therapy uses gentle vibrations to stimulate marma points, amplifying the therapeutic effects of traditional massage. It helps in accelerating healing, reducing stress, and improving overall energy levels, making it suitable for a variety of conditions.
              </p>
              <div className="mt-4 serviceappoitmentbtn">
                <Link to="/makeappointment" className="text-decoration-none pathakjibtn font-weight-bold">
                  Make An Appointment
                </Link>
              </div>
            </div>
            {/* <div className="col-lg-1 spacecol"></div> */}
            <div className="col-md-5  pl-lg-5">
              <Carousel>
                <Carousel.Item>
                  <img src={vt1} alt="Vibrator Marma Therapy" className="serviceimages" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={vt2} alt="Vibrator Marma Therapy" className="serviceimages" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={vt3} alt="Vibrator Marma Therapy" className="serviceimages" />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>

          {/* Combo Marma Therapy */}
          <div className="row py-lg-5 my-lg-5">
            <div className="col-md-6  order-2 order-md-1">
              <Carousel>
                <Carousel.Item>
                  <img src={ct1} alt="Combo Marma Therapy" className="serviceimages" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={ct2} alt="Combo Marma Therapy" className="serviceimages" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={ct3} alt="Combo Marma Therapy" className="serviceimages" />
                </Carousel.Item>
              </Carousel>
            </div>
            {/* <div className="col-lg-1 spacecol"></div> */}
            <div className="col-md-6 order-1 order-md-2">
              <h3 className="serviceheading">Combo Marma Therapy</h3>
              <p className="text-justify servicetext py-4">
                Combo Marma Therapy is a unique combination of marma therapies designed to target multiple body systems for comprehensive healing. By integrating different marma techniques, this therapy addresses specific health concerns, enhancing overall wellness and vitality. It is ideal for individuals looking for a balanced approach to health, addressing both physical and emotional aspects.
              </p>
              <div className="mt-4 serviceappoitmentbtn">
                <Link to="/makeappointment" className="text-decoration-none pathakjibtn font-weight-bold">
                  Make An Appointment
                </Link>
              </div>
            </div>
          </div>

          {/* Shirodhara Therapy */}
          <div className="row py-lg-5 my-lg-5">
            <div className="col-md-7">
              <h3 className="serviceheading">Shirodhara Therapy</h3>
              <p className="text-justify servicetext py-4">
                Shirodhara Therapy involves the gentle pouring of warm herbal oil over the forehead, known for its calming effects on the mind and nervous system. This therapy alleviates stress, anxiety, and headaches, promoting deep relaxation and mental clarity. It is particularly beneficial for insomnia and other sleep disorders, enhancing sleep quality and overall mental health.
              </p>
              <div className="mt-4 serviceappoitmentbtn">
                <Link to="/makeappointment" className="text-decoration-none pathakjibtn font-weight-bold">
                  Make An Appointment
                </Link>
              </div>
            </div>
            {/* <div className="col-lg-1 spacecol"></div> */}
            <div className="col-md-5">
              <Carousel>
                <Carousel.Item>
                  <img src={st1} alt="Shirodhara Therapy" className="serviceimages" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={st2} alt="Shirodhara Therapy" className="serviceimages" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={st3} alt="Shirodhara Therapy" className="serviceimages" />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>

          {/* Vasti Karma Therapy */}
          <div className="row py-lg-5 my-lg-5">
            <div className="col-md-6 order-2 order-md-1">
              <Carousel>
                <Carousel.Item>
                  <img src={vk1} alt="Vasti Karma Therapy" className="serviceimages" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={vk2} alt="Vasti Karma Therapy" className="serviceimages" />
                </Carousel.Item>
                <Carousel.Item>
                  <img src={vk3} alt="Vasti Karma Therapy" className="serviceimages" />
                </Carousel.Item>
              </Carousel>
            </div>
            {/* <div className="col-lg-1 spacecol"></div> */}
            <div className="col-md-6 order-1 order-md-2">
              <h3 className="serviceheading">Vasti Karma Therapy</h3>
              <p className="text-justify servicetext py-4">
                Vasti Karma Therapy is a specialized Ayurvedic treatment focusing on detoxifying and nourishing the body through the administration of herbal oils and decoctions. It is highly effective in treating lower back pain, arthritis, and other joint-related issues. This therapy enhances flexibility, strengthens muscles, and supports the body's natural detoxification processes, promoting overall health and wellness.
              </p>
              <div className="mt-4 serviceappoitmentbtn">
                <Link to="/makeappointment" className="text-decoration-none pathakjibtn font-weight-bold">
                  Make An Appointment
                </Link>
              </div>
            </div>
          </div>

        </section>
      </div>
    </div>
  );
};

export default Mainservices;
