import { put } from "redux-saga/effects";
import { success, failed } from '../../slice/productSlice'
import { getRequest } from "../ApiInstence";

export function* getProductAPI(){
    try {
        // You can also export the axios call as a function.
        const response = yield getRequest('product');
        console.log('i am called product')
        if (response?.status == 200) {
            console.log(response)
            yield put(success(response?.data));
        } else {
            yield put(failed(response));
        }

    } catch (error) {
        yield put(failed(error));
    }
}

export function* getOneProductAPI({payload}){
    try {
        // You can also export the axios call as a function.
        console.log('payload',payload)
        const response = yield getRequest(`product/one/${payload}`);
        if (response?.status == 200) {
            console.log(response)
            yield put(success([response?.data]));
        } else {
            yield put(failed(response));
        }

    } catch (error) {
        yield put(failed(error));
    }
}