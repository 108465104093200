import React, { useState } from 'react';
import { FaMapMarkerAlt, FaEnvelopeOpen, FaPhoneAlt } from 'react-icons/fa';
import contactbanner from '../../Assets/images/contactusbanner.png';
import { addContectUs, failed } from '../../slice/contactusSlice';
import { useDispatch, useSelector } from 'react-redux';
import loader from '../../common/loader/loader.gif';
import CommonAlert from '../../common/CommonAlert';

const Contact = () => {
    const dispatch = useDispatch();
    const data = useSelector(store => store.contact);
    
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        subject: '',
        message: '',
    });
    const [errors, setErrors] = useState({});

    // Validation function
    const validate = () => {
        const errors = {};
        if (!formData.name) {
            errors.name = "Name is required";
        }
        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(formData.mobile)) {
            errors.mobile = "Phone number must be exactly 10 digits";
        }
        if (!formData.subject) {
            errors.subject = "Subject is required";
        }
        if (!formData.message) {
            errors.message = "Message is required";
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {  // Call validate function before dispatching
            dispatch(addContectUs(formData));
        }
    };

    const onConfirm = () => {
        dispatch(failed());
    }

    return (
        <div>
            {data.response?.length && (
                <CommonAlert
                    title="Thanks For Contacting Us"
                    text="We will contact you soon"
                    icon="success"
                    confirmButtonText="Ok"
                    onConfirm={onConfirm}
                />
            )}
            <div className="border-0">
                <img className="bannerimges img-fluid" src={contactbanner} alt="Contact us banner" />
            </div>
            <div className="container mt-5 pt-4">
                <h2 className="text-center aboutheading">Contact Us</h2>
                <h6 className='text-center pt-2 pb-5'>
                    Get in Touch with Us for Any Queries, Feedback, or Support. We're Here to Help You!
                </h6>
                <div className="pt-lg-5 row">
                    <div className="col-lg-5 col-md-12 leftspace">
                        <div className="d-flex flex-column justify-content-center px-4 py-5 contactinfo">
                            <div className="d-flex my-3">
                                <FaMapMarkerAlt className="fa-2x contacticon flex-shrink-0 mr-3" />
                                <div>
                                    <h5 className="text-white">Address</h5>
                                    <p>
                                        <a className="text-white text-decoration-none" href="/" target="_blank" rel="noopener noreferrer">
                                            Dr. Pathak's Holistic Cure Bharat Bhavan Foujdar Mohalla Rajnagar District Chhatarpur MP, Rajnagar, Madhya Pradesh, India.
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex my-3">
                                <FaEnvelopeOpen className="fa-2x contacticon flex-shrink-0 mr-3" />
                                <div>
                                    <h5 className="text-white">Email Address</h5>
                                    <p><a href="mailto:Habbitliv@gmail.com" className="text-light text-decoration-none">yogidrraghav@gmail.com</a></p>
                                </div>
                            </div>
                            <div className="d-flex my-3">
                                <FaPhoneAlt className="fa-2x contacticon flex-shrink-0 mr-3" />
                                <div>
                                    <h5 className="text-white">Contact Number</h5>
                                    <p className="m-0"><a href="tel:+91-9236322922" className="text-light text-decoration-none">077480 18010</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 mb-lg-5">
                        <div className="contact-form bg-light mb-5 p-4">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-12 col-md-6 form-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control p-2"
                                            placeholder="Your Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                        {errors.name && <small className="text-danger">{errors.name}</small>}
                                    </div>
                                    <div className="col-12 col-md-6 form-group mb-3">
                                        <input
                                            type="tel"
                                            className="form-control p-2"
                                            placeholder="Your Phone"
                                            name="mobile"
                                            value={formData.mobile}
                                            onChange={handleChange}
                                            required
                                        />
                                        {errors.mobile && <small className="text-danger">{errors.mobile}</small>}
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <input
                                        type="text"
                                        className="form-control p-2"
                                        placeholder="Subject"
                                        name="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                        required
                                    />
                                    {errors.subject && <small className="text-danger">{errors.subject}</small>}
                                </div>
                                <div className="form-group mb-3">
                                    <textarea
                                        className="form-control py-2 px-4"
                                        rows="5"
                                        placeholder="Message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                    ></textarea>
                                    {errors.message && <small className="text-danger">{errors.message}</small>}
                                </div>
                                <div className="py-3">
                                    <button className="submitbtn" type="submit">
                                        {data?.loading ? <img src={loader} alt="loader" width={50} /> : 'Send Message'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3619.3641639772886!2d79.9068490748274!3d24.88555704425385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3982e974f564712d%3A0xbf75edeada4bf3ff!2sDr.%20Pathak&#39;s%20Holistic%20cure!5e0!3m2!1sen!2sin!4v1724610149098!5m2!1sen!2sin"
                    className="border-0 w-100"
                    height={300}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
        </div>
    );
};

export default Contact;
