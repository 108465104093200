import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
const Slider = (props) => {
  const [images, setImages] = useState([])
  useEffect(() => {
    setImages(props.images)
    console.log(props.images)
  }, [props.images])
  return (
    <>
      <div className='slider'>
        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            {images && images.length && images.map((item, index) => {
              return <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                <img className="d-block img-fluid w-100 carousel-image" src={`${process.env.REACT_APP_BACKEND}banner/${item.image}`} alt="First slide" />
              </div>
            })}
          </div>
          <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </>
  )
}
export default Slider;
