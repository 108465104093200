import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialState = {
  user: null,
  response: null,
  loading: false,
  error: null,
  callTimes: 0,
  message: ''
};



const userData = localStorage.getItem('user') !== 'undefined' && localStorage.getItem('user') !== undefined ? localStorage.getItem('user') : null;
if (userData && userData !== undefined) {
  initialState.response = userData ? JSON.parse(userData) : {};
  initialState.token = userData?.data?.access_token;
} else {
  initialState.response = null;
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signup: (state, { payload }) => {
      state.callTimes++;
    },
    signin: (state, { payload }) => {
      state.callTimes++;
    },
    failed: (state, { payload }) => {
      console.log(payload)
      state.error = true;
      state.loading = false;
      state.message = payload.message;
    },

    success: (state, { payload }) => {
      localStorage.setItem('user', JSON.stringify(payload?.data?.name))
      localStorage.setItem('token', payload?.data?.access_token)
      state.loading = false;
      state.response = payload;
      state.message = payload.message;
      state.token = payload?.data?.access_token;
      state.error = null;
    },
    logout: (state) => {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      state.response = [];
      state.loading = false;
      state.message = 'Logout Successfully';
    }
  },
})

// Action creators are generated for each case reducer function
export const { signup, signin, success, failed, logout } = userSlice.actions

export default userSlice.reducer