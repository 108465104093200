import React, { useState } from 'react';
import border from '../../Assets/images/border2.png';
import { useDispatch, useSelector } from 'react-redux';
import { addAppointment, failed } from '../../slice/contactusSlice';
import CommonAlert from '../../common/CommonAlert';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';


const Make_Appointment = () => {

  const dispatch = useDispatch()
  const data = useSelector(store => store.contact)
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    illness: '',
    previous_treatment: '',
    address: '',
    city: '',
    assign_doctor: '',
    appointment_date: '',
    appointment_type: '',
    patient_mobile: '',
    patient_mobile_whatsapp: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const validate = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = "Name is required";
    }
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(formData.mobile)) {
      errors.mobile = "Phone number must be 10 digits";
    }
    if (!formData.subject) {
      errors.subject = "Subject is required";
    }
    if (!formData.message) {
      errors.message = "Message is required";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'file' ? e.target.files[0] : value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();


    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    console.log(formData)
    // return 1;
    try {
      dispatch(addAppointment(formDataToSend))
      setFormData({
        name: '',
        age: '',
        illness: '',
        previous_treatment: '',
        address: '',
        city: '',
        assign_doctor: '',
        appointment_date: '',
        appointment_type: '',
        patient_mobile: '',
        patient_mobile_whatsapp: '',
        message: '',
      })
    } catch (error) {
      console.error('There was a problem adding the product:', error.message);
    }

  };

  const onConfirm = () => {
    dispatch(failed())
  }

  const [selectedDate, setSelectedDate] = useState(new Date());

  // Get today's date
  const today = new Date();


  return (
    <div>
      {
        data.response?.length && <CommonAlert
          title="Appointment Book Successfully"
          text="We will contact you soon"
          icon="success"
          confirmButtonText="Ok"
          onConfirm={onConfirm}
        />
      }
      <div className='makeandappointmentbg' id='appointment'>
        <section id="appointment" className="appointment section">
          <div className="container section-title text-center" data-aos="fade-up">
            <h3 className='text-center appointmentheading  pt-5 pb-2'>Appointment</h3>
            <h6 className='text-center pb-2'>Book an appointment for personalized therapy and care.</h6>
            <img src={border} className="img-fluid pb-5" alt='border' width={200} />
          </div>
          <div className="container appointmentinputrow" data-aos="fade-up" data-aos-delay="100">
            <form className="php-email-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4 form-group">
                  <label htmlFor="name">Patient's Name</label>
                  <input type="text" name="name" onChange={handleChange} className="form-control" id="name" required />
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <label htmlFor="age">Patient's Age</label>
                  <input type="text" className="form-control" name="age" onChange={handleChange} id="age" required />
                </div>
                <div className="col-md-4 form-group ">
                  <label htmlFor="service">Details of Illness/Condition</label>
                  <input type="text" className="form-control" name="illness" onChange={handleChange} id="service" required />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <label htmlFor="previous_treatment">Ongoing Treatment, if any?<span className='ml-2'>(Optional)</span></label>
                  <input type="text" name="previous_treatment" onChange={handleChange} className="form-control" id="treatment" />
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <label htmlFor="address">Patient's Address</label>
                  <input type="text" className="form-control" name="address" onChange={handleChange} id="email" />
                </div>
                <div className="col-md-4 form-group">
                  <label htmlFor="doctor">Nearest District or City</label>
                  <select name="city" id="service" onChange={handleChange} className="form-select custom-select" required>
                    <option value="">Select City</option>
                    <option value="Khajuraho">Khajuraho</option>
                    <option value="Chhatarpur">Chhatarpur</option>
                    <option value="Jabalpur">Jabalpur</option>
                    <option value="Rewa">Rewa</option>
                    <option value="Pendra">Pendra</option>
                    <option value="Narsinghpur">Narsinghpur</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 form-group mt-3">
                  <label htmlFor="doctor">Medical Specialist</label>
                  <select name="assign_doctor" onChange={handleChange} id="doctor" className="form-select custom-select">
                    <option value="">Select Doctor</option>
                    <option value="Dr.Raghav Pathak">Dr.Raghav Pathak</option>
                    <option value="Dr.Rina Mudiya">Dr.Rina Mudiya</option>
                  </select>
                </div>
                <div className="col-md-2 form-group mt-3">
                  <label htmlFor="date">Appointment Date</label>
                  <input type="date" name="appointment_date" onChange={handleChange} className="form-control datepicker" id="date" required="" />
                  {/* <div className='w-100'>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="yyyy/MM/dd"
                    minDate={today} // Restrict past dates
                    placeholderText={`Select a date (Today: ${format(today, 'yyyy/MM/dd')})`}
                    className='form-control datepicker w-100'
                  />
                  </div> */}
                </div>
                <div className="col-md-2 form-group mt-3">
                  <label htmlFor="time">Appointment Time</label>
                  <select
                    name="appointment_time"
                    onChange={handleChange}
                    id="service"
                    className="form-select custom-select"
                    required
                  >
                    <option value="">Select Time</option>
                    <option value="3:00 PM - 3:15 PM">3:00 PM - 3:15 PM</option>
                    <option value="3:15 PM - 3:30 PM">3:15 PM - 3:30 PM</option>
                    <option value="3:30 PM - 3:45 PM">3:30 PM - 3:45 PM</option>
                    <option value="3:45 PM - 4:00 PM">3:45 PM - 4:00 PM</option>
                    <option value="4:00 PM - 4:15 PM">4:00 PM - 4:15 PM</option>
                    <option value="4:15 PM - 4:30 PM">4:15 PM - 4:30 PM</option>
                    <option value="4:30 PM - 4:45 PM">4:30 PM - 4:45 PM</option>
                    <option value="4:45 PM - 5:00 PM">4:45 PM - 5:00 PM</option>
                    <option value="5:00 PM - 5:15 PM">5:00 PM - 5:15 PM</option>
                    <option value="5:15 PM - 5:30 PM">5:15 PM - 5:30 PM</option>
                    <option value="5:30 PM - 5:45 PM">5:30 PM - 5:45 PM</option>
                    <option value="5:45 PM - 6:00 PM">5:45 PM - 6:00 PM</option>
                    <option value="6:00 PM - 6:15 PM">6:00 PM - 6:15 PM</option>
                    <option value="6:15 PM - 6:30 PM">6:15 PM - 6:30 PM</option>
                    <option value="6:30 PM - 6:45 PM">6:30 PM - 6:45 PM</option>
                    <option value="6:45 PM - 7:00 PM">6:45 PM - 7:00 PM</option>
                  </select>
                </div>
                <div className="col-md-4 form-group mt-3">
                  <label htmlFor="doctor">Appointment Type</label>
                  <select name="appointment_type" onChange={handleChange} id="service" className="form-select custom-select" required>
                    <option value="">Select type</option>
                    <option value="Online">Online</option>
                    <option value="Offline">Offline</option>
                  </select>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <label htmlFor="name">Patient's or caretaker's phone number</label>
                  <input type="text" name="patient_mobile"
                   onChange={handleChange} className="form-control" 
                   id="phonenumber"
                   pattern="\d{10}"   
                   title="Please enter a 10-digit Phone number"
                   required />
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <label htmlFor="email">Patient's or caretaker's WhatsApp number (Optional) </label>
                  <input type="text" className="form-control"
                   name="patient_mobile_whatsapp" 
                   onChange={handleChange} id="whatsappnumber"
                   pattern="\d{10}" 
                   title="Please enter a 10-digit WhatsApp number"
                />
                </div>
                <div className="col-md-4 form-group mt-3 mt-md-0">
                  <label htmlFor="file">Upload any extra information patient reports/X-rays</label>
                  <label className="custom-file-upload" htmlFor="file">
                    Choose File
                  </label>
                  <input type="file" name="image" id="file" onChange={handleChange} className="form-control-file" />
                </div>

              </div>
              <div className="form-group mt-3">
                <label htmlFor="message">Message (Optional)</label>
                <textarea className="form-control" name="message" onChange={handleChange} id="message" rows="3"></textarea>
              </div>
              <div className="mt-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your appointment request has been sent successfully. Thank you!</div>
                <div className="text-center py-5">
                  <button type="submit" className='makeappointmentbtn'>Make an Appointment</button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Make_Appointment;
