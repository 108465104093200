import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { IoLogoYoutube, IoCall, IoCart, IoClose, IoMenu } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import logo from '../Assets/images/logo.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../slice/userSlice';

const Header = () => {
    const cartData = useSelector((store) => store.cart);
    const userData = useSelector((store) => store.user);
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const currentLocation = useLocation();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleMenuItemClick = () => {
        setIsOpen(false);
    };

    return (
        <div>
             <marquee style={{'background':'red', 'padding':'5px'}}> <b style={{'color':'white'}}> Our website is currently under construction. We're working hard to give you a better experience, and we'll be up and running soon!</b> </marquee>
            <header id="header" className="header sticky-top">
                <div className="topbar d-flex align-items-center">
                    <div className="container d-flex justify-content-md-center justify-content-md-between">
                        <div className="contact-info d-flex align-items-center text-white">
                            <a href="mailto:yogidrraghav@gmail.com" className="text-decoration-none text-white ml-lg-1">
                                yogidrraghav@gmail.com
                            </a>
                            <IoCall className="d-flex align-items-center ml-3" />
                            <a href="tel:+8516064764" className="text-decoration-none text-white ml-1">
                                8516064764
                            </a>
                            <a href="tel:+7566064764" className="text-decoration-none text-white ml-3 ">
                                7566064764
                            </a>
                        </div>
                        
                       

                        <div className="social-links d-none d-md-flex align-items-center mr-lg-2">
                            <a href="https://twitter.com/PathakCure20979" className="twitter text-white" target="_blank" rel="noopener noreferrer">
                                <FaTwitter />
                            </a>
                            <a href="https://www.facebook.com/Drpathakscure" className="facebook text-white" target="_blank" rel="noopener noreferrer">
                                <FaFacebook />
                            </a>
                            <a href="https://www.instagram.com/drpathaksholisticcure" className="instagram text-white" target="_blank" rel="noopener noreferrer">
                                <FaInstagram />
                            </a>
                            <a href="https://youtube.com/@drraghavpathak" className="youtube text-white" target="_blank" rel="noopener noreferrer">
                                <IoLogoYoutube />
                            </a>
                            <a href="https://wa.me/917748018010" className="whatsapp text-white" target="_blank" rel="noopener noreferrer">
                                <FaWhatsapp />
                            </a>

                        </div>
                    </div>
                </div>
                <div className="branding d-flex align-items-center">
                    <div className="container position-relative d-flex align-items-center justify-content-between">
                        <Link to="/" className="logo d-flex align-items-center me-auto">
                            <img src={logo} alt='drpathaks' className='img-fluid logo' />
                        </Link>
                        <nav id="navmenu" className={`navmenu ${isOpen ? 'open' : ''}`}>
                            <ul>
                                <li className="nav-item active">
                                    <Link className="nav-link mymenu" to="/" onClick={handleMenuItemClick}>Home</Link>
                                </li>
                                <li className="nav-item active">
                                    <Link className="nav-link mymenu" to="/services" onClick={handleMenuItemClick}>Services</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mymenu" to="/medicines" onClick={handleMenuItemClick}>Medicines</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mymenu" to="/aboutus" onClick={handleMenuItemClick}>About</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mymenu" to="/ourteam" onClick={handleMenuItemClick}>Our Team</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle mymenu d-flex align-items-center" to="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Media
                                    </Link>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <Link className="dropdown-item pl-3" to="/photogallery" onClick={handleMenuItemClick}>Photo Gallery</Link>
                                        <Link className="dropdown-item pl-3" to="/medianews" onClick={handleMenuItemClick}>Media & News</Link>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mymenu" to="/contact" onClick={handleMenuItemClick}>Contact Us</Link>
                                </li>

                                {userData && userData?.response?.length ?
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle mymenu d-flex align-items-center" to="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            User
                                        </Link>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                            <Link className="dropdown-item pl-3" to="/myorder" onClick={handleMenuItemClick}>My Order</Link>
                                            <Link className="dropdown-item pl-3" to="/myprofile" onClick={handleMenuItemClick}>My Profile</Link>
                                            <button className="dropdown-item pl-3 bg-danger" onClick={() => dispatch(logout())}>Logout</button>
                                        </div>
                                    </li>
                                    :
                                    <li className="nav-item">
                                        <Link className="nav-link mymenu" to="/login" onClick={handleMenuItemClick}>Login</Link>
                                    </li>
                                }

                                <li className="nav-item">
                                    <Link className="nav-link mymenu" to="/cart" onClick={handleMenuItemClick}>
                                        Cart<IoCart className='ml-2' size={20} /><span>{cartData.length}</span>
                                    </Link>
                                </li>
                            </ul>
                        </nav>

                        <Link to="/makeappointment" className="cta-btn d-sm-block ml-lg-5 ml-sm-0 text-decoration-none">Make an Appointment</Link>
                        <button className="mobile-nav-toggle d-xl-none text-decoration-none border-0" type="button" onClick={toggleMenu} aria-expanded={isOpen} aria-label="Toggle navigation">
                            {isOpen ? <IoClose size={24} /> : <IoMenu size={24} />}
                        </button>
                    </div>
                </div>
            </header>
        </div>
    );
};

export default Header;
