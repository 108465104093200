// CounterSection.jsx
import React from 'react';
import SingleCounter from '../components/SingleCounter'
import 'bootstrap/dist/css/bootstrap.min.css';

const CounterSection = () => {
    return (
        <div className='makeandappointmentbg'>
            <div className='container'>
                <div className='row '>
                    <div className='col-lg-12 col-md-6'>
                        <div className="row py-lg-5">
                            <div className="col-lg-3 col-md-12 counts">
                                <SingleCounter icon="doctor" target={3} duration={1500} text="Doctors Available" />
                            </div>
                            <div className="col-lg-3 col-md-12 counts">
                                <SingleCounter icon="hospital" target={7} duration={1500} text="Locations" />
                            </div>
                            <div className="col-lg-3 col-md-12 counts">
                                <SingleCounter icon="beds" target={5000} duration={1500} text="Successful Treatments" />
                            </div>
                            <div className="col-lg-3 col-md-12 counts">
                                <SingleCounter icon="injections" target={5000} duration={1500} text="Happy Patient" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CounterSection;
