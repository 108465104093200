import React, {useEffect, useState} from 'react'
import Slider from '../../components/Slider'
import Quickinfo from '../../components/Quickinfo'
import Socailmedia from '../../components/Socailmedia'
import Appointment from '../../pages/appiontment/Make_Appointment'
import HowWeWork from '../../components/HowWeWork'
import CounterSection from '../../components/CounterSection'
import Feedback from '../../components/Feedback'
import Mainservices from '../services/Mainservices'
import Faq from '../../components/Faq'
import Contctus from '../../pages/contact/Contactus'
import Medicinehome from '../../pages/medicines/Medicinehome'
import Doctors from '../drpathakji/Doctors';
import axios from 'axios';


const Home = () => {

  const [homeData, setHomeData] = useState({})

  const getHome = () => {
    axios.get(process.env.REACT_APP_BACKEND_URL + '/home').then((data) => {
      if (data) {
        setHomeData(data.data)
      }
    })
  }
  console.log(homeData)
  useEffect(() => {
    getHome()
  }, [])

  return (
    <div>
      <Slider images={homeData.banner_data} />
      <Quickinfo />
      <Mainservices />
      <Doctors />
      <Appointment />
      <Medicinehome product_data={homeData.product_data?.length ? homeData.product_data : []} />
      <HowWeWork />
      <Socailmedia />
      <CounterSection />
      <Faq />
      <Feedback />
      <Contctus />
    </div>
  )
}

export default Home
