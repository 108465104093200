import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import at1 from '../../Assets/images/at1.jpeg'
import at2 from '../../Assets/images/at2.jpeg'
import at3 from '../../Assets/images/at3.jpeg'
import mt1 from '../../Assets/images/mt1.jpeg'
import mt2 from '../../Assets/images/mt2.jpeg'
import mt3 from '../../Assets/images/mt3.jpeg'
import vt1 from '../../Assets/images/vt1.jpeg'
import vt2 from '../../Assets/images/vt2.jpeg'
import vt3 from '../../Assets/images/vt3.jpeg'
import ct1 from '../../Assets/images/cmt1.jpeg'
import ct2 from '../../Assets/images/cmt2.jpeg'
import ct3 from '../../Assets/images/cmt3.jpeg'
import st1 from '../../Assets/images/st1.jpeg'
import st2 from '../../Assets/images/st2.jpeg'
import st3 from '../../Assets/images/st3.jpeg'
import vk1 from '../../Assets/images/vt1.jpeg'
import vk2 from '../../Assets/images/vt2.jpeg'
import vk3 from '../../Assets/images/vt3.jpeg'

const Mainservices = () => {
  return (
    <div>

      <div className='serviceback' id='services'>
        <section id="departments" className="departments section">
          <div className="container section-title text-center" data-aos="fade-up">
            <h2 className='text-center aboutheading'>Our Services</h2>
            <h6 className='text-center pt-2'>Explore our range of holistic therapies designed to rejuvenate and heal your body</h6>

          </div>
          <div className="container my-5 py-5" data-aos="fade-up" data-aos-delay="100">
            <div className="row">
              <div className="col-lg-3 navtabbg">
                <ul className="nav nav-tabs flex-column">
                  <li className="nav-item">
                    <a className="nav-link  active" data-toggle="tab" href="#departments-tab-1">Abhyangam Therapy</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#departments-tab-2">Marma Therapy</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#departments-tab-3">Vibrator Marma Therapy</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#departments-tab-4">Combo Marma Therapy</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#departments-tab-5">Shirodhara Therapy</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#departments-tab-6">Vasti Karma Therapy</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-9 mt-4 mt-lg-0">
                <div className="tab-content">
                  <div className="tab-pane active" id="departments-tab-1">
                    <div className="row">
                      <div className="col-lg-7 details order-2 order-lg-1">
                        <h3 className='serviceheading'>Abhyangam Therapy</h3>
                        <p className="pt-2 servicetext">Abhyangam is a traditional Ayurvedic full-body massage that rejuvenates and detoxifies the body, promoting overall health and well-being. Using warm herbal oils and gentle techniques, it balances the doshas, improves circulation, and nourishes tissues, providing a profound sense of relaxation and revitalization. </p>
                      </div>
                      <div className="col-lg-5 text-center order-1 order-lg-2">
                        <Carousel>
                          <Carousel.Item>
                            <img src={at1} alt="Abhyangam Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={at2} alt="Abhyangam Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={at3} alt="Abhyangam Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="departments-tab-2">
                    <div className="row">
                      <div className="col-lg-7 details order-2 order-lg-1">
                        <h3 className='serviceheading'>Marma Therapy</h3>
                        <p className="pt-2 servicetext">Marma Therapy involves massaging the body's vital points, enhancing energy flow and promoting healing. This ancient Ayurvedic technique targets specific areas to release blocked energy, relieve pain, and restore balance to the body's natural functions. It is particularly effective in improving overall vitality and wellness.</p>
                      </div>
                      <div className="col-lg-5 text-center order-1 order-lg-2">
                        <Carousel>
                          <Carousel.Item>
                            <img src={mt1} alt="Marma Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={mt2} alt="Marma Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={mt3} alt="Marma Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="departments-tab-3">
                    <div className="row">
                      <div className="col-lg-7 details order-2 order-lg-1">
                        <h3 className='serviceheading'>Vibrator Marma Therapy</h3>
                        <p className="pt-2 servicetext">Vibrator Marma Therapy combines vibration techniques with marma point massage to relieve tension and improve circulation. This specialized therapy uses gentle vibrations to stimulate marma points, amplifying the therapeutic effects of traditional massage. It is excellent for relieving muscle stiffness and enhancing energy flow.</p>
                      </div>
                      <div className="col-lg-5 text-center order-1 order-lg-2">
                        <Carousel>
                          <Carousel.Item>
                            <img src={vt1} alt="Marma Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={vt2} alt="Marma Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={vt3} alt="Marma Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="departments-tab-4">
                    <div className="row">
                      <div className="col-lg-7 details order-2 order-lg-1">
                        <h3 className='serviceheading'>Combo Marma Therapy</h3>
                        <p className="pt-2 servicetext">Combo Marma Therapy is a unique combination of marma therapies designed to target multiple body systems for comprehensive healing. By integrating different marma techniques, this therapy addresses specific health concerns, enhancing overall wellness and vitality. It is tailored to meet individual needs, promoting holistic recovery.</p>
                      </div>
                      <div className="col-lg-5 text-center order-1 order-lg-2">
                        <Carousel>
                          <Carousel.Item>
                            <img src={ct1} alt="Marma Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={ct2} alt="Marma Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={ct3} alt="Marma Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="departments-tab-5">
                    <div className="row">
                      <div className="col-lg-7 details order-2 order-lg-1">
                        <h3 className='serviceheading'>Shirodhara Therapy</h3>
                        <p className="pt-2 servicetext">Shirodhara Therapy involves the gentle pouring of warm herbal oil over the forehead, known for its calming effects on the mind and nervous system. This therapy alleviates stress, anxiety, and headaches, promoting deep relaxation and mental clarity. It is widely used for its therapeutic benefits in calming the mind and body.</p>
                      </div>
                      <div className="col-lg-5 text-center order-1 order-lg-2">
                        <Carousel>
                          <Carousel.Item>
                            <img src={st1} alt="Marma Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={st2} alt="Marma Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={st3} alt="Marma Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="departments-tab-6">
                    <div className="row">
                      <div className="col-lg-7 details order-2 order-lg-1">
                        <h3 className='serviceheading'>Vasti Karma Therapy</h3>
                        <p className="pt-2 servicetext">Vasti Karma Therapy focuses on detoxifying the lower body through targeted treatments, beneficial for many conditions. This therapy is particularly effective in relieving joint pain, improving digestion, and enhancing overall detoxification processes. It is a crucial part of Ayurvedic cleansing and rejuvenation practices.</p>
                      </div>
                      <div className="col-lg-5 text-center order-1 order-lg-2">
                        <Carousel>
                          <Carousel.Item>
                            <img src={vk1} alt="Marma Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={vk2} alt="Marma Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={vk3} alt="Marma Therapy" className="img-fluid serviceimages" />
                          </Carousel.Item>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Mainservices;