import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const Myprofile = () => {
  const [formData, setFormData] = useState({
    username: 'John Doe',
    email: 'john.doe@example.com',
    mobile: '1234567890',
    whatsapp: '1234567890',
    houseNo: '123 Main St',
    city: 'New York',
    state: 'NY',
    zip: '10001',
    password: 'password123'
  });

  const [isEditing, setIsEditing] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Profile updated successfully');
    setIsEditing(false);
  };

  return (
    <>
      <div className=" myprofilebg d-flex align-items-center justify-content-center">
        <div className="container my-5 ">   
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-8 col-md-8">
              <div className="bg-light p-5 rounded">
                <form onSubmit={handleSubmit}>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control p-2 registerinputs"
                          placeholder="Full Name"
                          name="username"
                          value={formData.username}
                          onChange={handleChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group mb-3">
                        <input
                          type="email"
                          className="form-control p-2 registerinputs"
                          placeholder="Email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className="form-group mb-3">
                        <input
                          type="tel"
                          className="form-control p-2 registerinputs"
                          placeholder="Mobile Number"
                          name="mobile"
                          value={formData.mobile}
                          onChange={handleChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className="form-group mb-3">
                        <input
                          type="tel"
                          className="form-control registerinputs p-2"
                          placeholder="WhatsApp Number"
                          name="whatsapp"
                          value={formData.whatsapp}
                          onChange={handleChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 colinmobile'>
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control p-2 registerinputs"
                          placeholder="House No/Landmark"
                          name="houseNo"
                          value={formData.houseNo}
                          onChange={handleChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control p-2 registerinputs"
                          placeholder="City"
                          name="city"
                          value={formData.city}
                          onChange={handleChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control p-2 registerinputs"
                          placeholder="State"
                          name="state"
                          value={formData.state}
                          onChange={handleChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control p-2 registerinputs"
                          placeholder="Zip / Postal Code"
                          name="zip"
                          value={formData.zip}
                          onChange={handleChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 colinmobile'>
                      <div className="form-group mb-3">
                        <input
                          type="password"
                          className="form-control p-2 registerinputs"
                          placeholder="Password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                          readOnly={!isEditing}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group text-center">
                    <button
                      className="px-4 py-1 loginbtn border btn-block mt-4 rounded"
                      type="button"
                      onClick={handleEditToggle}
                    >
                      {isEditing ? 'Save' : 'Edit'}
                    </button>
                    {isEditing && (
                      <button
                        className="px-4 py-1 loginbtn border btn-block mt-4 rounded"
                        type="submit"
                      >
                        Save Changes
                      </button>
                    )}
                    <p className='pt-3'>Want to logout? <Link className='rbtn' to="/login">Logout</Link></p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Myprofile;
