import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OwlCarousel from 'react-owl-carousel';
import { Card } from 'react-bootstrap';

const Feedback = () => {
  return (
    <div className="container pt-lg-5 my-5">
      <div className="section-title">
      <div className="container section-title text-center" data-aos="fade-up">
            <h2 className='text-center aboutheading'>Happy Clients</h2>
            <h6 className='text-center pt-2 pb-lg-5'>Our Clients' Experiences and Success Stories</h6>
           
           </div>
      </div>
      <div>
        <OwlCarousel className="owl-theme pt-lg-5" loop margin={10} nav items={1}>
        <div className="item">
            <div className="d-flex flex-column flex-md-row text-center">
              <Card className="h-100 border-0">
                <Card.Body>
                  <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon-feedback" />
                  <Card.Text className="text-center feedtext">
                  It's been 3 months since I started eating your food, and I feel greatly relieved now. Thank you, sir!                  </Card.Text>
                  <div className="rating text-center py-lg-3 text-warning">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <Card.Title className="text-uppercase mt-3 text-center">Aneeta Ravindra Ahirwar</Card.Title>
                </Card.Body>
              </Card>
              <Card className="h-100 border-0">
                <Card.Body>
                  <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon-feedback" />
                  <Card.Text className="text-center feedtext">
                  Great treatment with homeopathy and ayurvedic methods. I experienced significant relief from your care, sir 😊🙏                  </Card.Text>
                  <div className="rating text-center py-lg-3 text-warning">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <Card.Title className="text-uppercase mt-3 text-center">Faimeeda Khatoon</Card.Title>
                </Card.Body>
              </Card>
              <Card className="h-100 border-0">
                <Card.Body>
                  <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon-feedback" />
                  <Card.Text className="text-center feedtext">
                  I am 100% satisfied with your treatment. I was suffering a lot due to sciatica, and now I feel much better. Thank you, sir 🙏🏻🙏🏻😊😊.                  </Card.Text>
                  <div className="rating text-center py-lg-3 text-warning">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <Card.Title className="text-uppercase mt-3 text-center">Shivam “SHIV”</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="item feedbackitems">
            <div className="d-flex flex-column flex-md-row text-center ">
              <Card className="h-100 border-0">
                <Card.Body>
                  <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon-feedback" />
                  <Card.Text className="text-center feedtext">
                  Dr. Pathak's treatment for sciatica pain has been incredibly effective. His holistic approach, combining physical therapy and personalized care, not only relieved my pain but also addressed the root cause. Highly recommended for anyone suffering from sciatica!
                  </Card.Text>
                  <div className="rating text-center py-lg-3 text-warning">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <Card.Title className="text-uppercase mt-3 text-center">माधव शरण पाठक</Card.Title>
                </Card.Body>
              </Card>
              <Card className="h-100 border-0">
                <Card.Body>
                  <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon-feedback" />
                  <Card.Text className="text-center feedtext">
                  Dr. Pathak provides excellent treatment with a holistic approach, ensuring 100% satisfaction. The quality of care and personalized attention in addressing health issues is truly commendable.                  </Card.Text>
                  <div className="rating text-center py-lg-3 text-warning">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <Card.Title className="text-uppercase mt-3 text-center">pragati patairiya</Card.Title>
                </Card.Body>
              </Card>
              <Card className="h-100 border-0">
                <Card.Body>
                  <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon-feedback" />
                  <Card.Text className="text-center feedtext">
                  Had a great experience with Dr. Pathak's treatment and achieved 100% relief!                  </Card.Text>
                  <div className="rating text-center py-lg-3 text-warning">
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                    <span className="fa fa-star checked"></span>
                  </div>
                  <Card.Title className="text-uppercase mt-3 text-center">DHAIRYA VLOGGER</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </div>
         
        </OwlCarousel>
      </div>
    </div>
  );
};

export default Feedback;