import React, { useState } from 'react'
import RenderRazorpay from '../../components/payment/RenderRazorpay';

function Payment() {
    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };
    return (
        <div>
            <h1> Make Payment</h1>
            <label>
                <input
                    type="radio"
                    value="online"
                    checked={selectedOption === 'online'}
                    onChange={handleOptionChange}
                />
                Online Payment
            </label>
            <br />
            <label>
                <input
                    type="radio"
                    value="COD"
                    checked={selectedOption === 'COD'}
                    onChange={handleOptionChange}
                />
                Cash On Delivery
            </label>
            {selectedOption == 'online' && <RenderRazorpay
                orderId={'order_OooqXLNodKcBeM'}
                keyId={'rzp_test_7BKEnaLq7HKuMD'}
                keySecret={'6A0pWyz9dTCfAxQCotQbMvhw'}
                currency={'INR'}
                amount={100}
            />}
        </div>
    )
}

export default Payment