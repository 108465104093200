import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { useDispatch} from 'react-redux'
import {signin} from '../../slice/userSlice';


const Login = () => {
  const [formData, setFormData] = useState({ username: '', email: '', password: '', mobile: '' });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.email) tempErrors.email = "Email is required";
    if (!formData.password) tempErrors.password = "Password is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(signin(formData))
      alert("Registration successful");
    }
  };

  return (
    <>
      <div className="maincontainer d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-8 col-md-8">
              <div className="bg-light p-5 rounded">
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control p-2 registerinputs"
                      placeholder="Enter Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    {errors.email && <small className="text-danger">{errors.email}</small>}
                  </div>            
                  <div className="form-group mb-3">
                    <input
                      type="password"
                      className="form-control p-2 registerinputs"
                      placeholder="Enter password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                    {errors.password && <small className="text-danger">{errors.password}</small>}
                  </div>
                  <div className="form-group text-center">
                    <button className="px-4 py-1 loginbtn border btn-block mt-4 rounded" type="submit">Login</button>
                    <p className='pt-3'>Dont have an account? <Link className='rbtn' to="/register">Register</Link></p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
