import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector } from 'react-redux';

const Checkout = () => {
    const cartData = useSelector((store) => store.cart);
    const nevigte = useNavigate()
    const [formData, setFormData] = useState({
        country: '',
        full_name: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        phone_number: '',
        email_address: '',
    });

    const [showPopup, setShowPopup] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        nevigte('/payment')
        setShowPopup(true);
    };

    return (
        <div>
        <div className="container my-5 pb-5">
            <h3 className='text-center pb-5 aboutheading'>Checkout</h3>
            <div className="row">
                <div className="col-lg-6">
                    <form onSubmit={handleSubmit}>
                        <div className="card mb-4">
                            <div className="card-header custom-cart-header">
                                <h5>Shipping Address</h5>
                            </div>
                            <div className="card-body">
                                <div className="form-row">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="full_name">Full Name:</label>
                                        <input
                                            type="text"
                                            name="full_name"
                                            className="form-control cehckoutinput"
                                            value={formData.full_name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="address">House No/Landmark</label>
                                    <input
                                        type="text"
                                        name="address"
                                        className="form-control cehckoutinput"
                                        value={formData.address}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label htmlFor="city">City</label>
                                        <input
                                            type="text"
                                            name="city"
                                            className="form-control cehckoutinput"
                                            value={formData.city}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="state">State</label>
                                        <input
                                            type="text"
                                            name="state"
                                            className="form-control cehckoutinput"
                                            value={formData.state}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="zip_code">Zip / Postal Code</label>
                                        <input
                                            type="text"
                                            name="zip_code"
                                            className="form-control cehckoutinput"
                                            value={formData.zip_code}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="phone_number">Phone Number</label>
                                        <input
                                            type="text"
                                            name="phone_number"
                                            className="form-control cehckoutinput"
                                            value={formData.phone_number}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="email_address">Email Address</label>
                                        <input
                                            type="email"
                                            name="email_address"
                                            className="form-control cehckoutinput"
                                            value={formData.email_address}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="py-1 loginbtn btn-block">Make Payment</button>
                        </div>
                    </form>
                </div>
                <div className="col-lg-6">
                    <div className="card mb-4">
                        <div className="card-header custom-cart-header">
                            <h5>Review Order <span><Link to="/cart" className="float-right text-white text-decoration-none">Edit Cart</Link></span></h5>
                        </div>
                        <div className="card-body">
                            {cartData.map((item, index) => (
                                <div key={index} className="d-flex mb-3">
                                    <div className="mr-3">
                                        <img className='img-fluid' width={50} src={`${process.env.REACT_APP_BACKEND_URL_REL}${item.image}`} alt={item.name} />
                                    </div>
                                    <div className="flex-grow-1">
                                        <div>{item.name}</div>
                                        <small>Quantity: {item.qty}</small>
                                    </div>
                                    <div className="text-right">
                                        <h6>{item.sprice * item.qty}.00</h6>
                                    </div>
                                </div>
                            ))}
                            <div className="border-top pt-3">
                                <div className="d-flex justify-content-between">
                                    <strong>Subtotal</strong>
                                    <span>$200.00</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <small>Shipping</small>
                                    <span>-</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <strong>Order Total</strong>
                                    <span>$150.00</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showPopup && (
                <div className="modal fade show" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Thank You!</h5>
                                <button type="button" className="close" onClick={() => setShowPopup(false)}>
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Your order has been placed successfully.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowPopup(false)}>Close</button>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show" onClick={() => setShowPopup(false)}></div>
                </div>
            )}
        </div>
        </div>
    );
};

export default Checkout;
