import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import medi1 from '../../Assets/images/medi1.png';
import { FaSearch} from 'react-icons/fa';
import meicinebanner from '../../Assets/images/medicines.png'
import Commonhero from '../../components/Commonhero';
import { useDispatch, useSelector } from 'react-redux';
import { getProduct,  } from '../../slice/productSlice';

const Medicines = () => {
  const [visibleCount, setVisibleCount] = useState(6);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProduct())
  }, [dispatch])

  const data = useSelector(( store )=> store.product )
  console.log(data)



  const products = data ? data : [
    { id: 1, name: 'Product 1', price: 61.99, imgUrl: medi1, discription: "Lorem ipsum dolor sit amet, consectetur" },
    { id: 2, name: 'Product 2', price: 61.99, imgUrl: medi1, discription: "Lorem ipsum dolor sit amet, consectetur" },
    { id: 3, name: 'Product 3', price: 50.99, imgUrl: medi1, discription: "Lorem ipsum dolor sit amet, consectetur" },
    { id: 4, name: 'Product 4', price: 50.99, imgUrl: medi1, discription: "Lorem ipsum dolor sit amet, consectetur " },
    { id: 5, name: 'Product 5', price: 61.99, imgUrl: medi1, discription: "Lorem ipsum dolor sit amet, consectetur" },
    { id: 6, name: 'Product 6', price: 50.99, imgUrl: medi1, discription: "Lorem ipsum dolor sit amet, consectetur " },
    { id: 7, name: 'Product 7', price: 50.99, imgUrl: medi1, discription: "Lorem ipsum dolor sit amet, consectetur " },
    { id: 8, name: 'Product 8', price: 50.99, imgUrl: medi1, discription: "Lorem ipsum dolor sit amet, consectetur " },
    { id: 9, name: 'Product 9', price: 50.99, imgUrl: medi1, discription: "Lorem ipsum dolor sit amet, consectetur " },
    { id: 10, name: 'Product 10', price: 50.99, imgUrl: medi1, discription: "Lorem ipsum dolor sit amet, consectetur " },
    { id: 11, name: 'Product 11', price: 50.99, imgUrl: medi1, discription: "Lorem ipsum dolor sit amet, consectetur " },
    { id: 12, name: 'Product 12', price: 50.99, imgUrl: medi1, discription: "Lorem ipsum dolor sit amet, consectetur " }
  ];

  const showMoreItems = () => {
    setVisibleCount((prevCount) => prevCount + 6);
  };

  return (
    <div>
      <div class="border-0">
                <img className="bannerimges img-fluid" src={meicinebanner} alt="Card image" />   
      </div>
      <div className='container my-5 py-5'>
        <h2 className='text-center aboutheading'>All Medicines </h2>
        <h6 className='text-center pt-2 pb-5'>Quality Medicines for Optimal Health and Wellbeing</h6>
        <div className="row text-center pb-5">
          <div className='col-3'></div>
          <div className='col-md-6 d-flex justify-content-center align-items-center sinput rounded'>
            <input type="text" className="w-100" placeholder="Search medicines here ..." />
            <button type="button" className="btn">
              <FaSearch className='searchicon' />
            </button>
          </div>
          <div className='col-3'></div>
        </div>
        <div className="row pt-5">
          {console.log('pppppppp', products)}
          {products.length && products.slice(0, visibleCount).map(product => (
            <div key={product.id} className="col-md-4 mb-5">
              <div className="product text-center">
                <div className="position-relative product-hover">
                  <img className="product-image w-100   p-4" src={ process.env.REACT_APP_BACKEND_URL_REL + product.image} alt={product.name} />
                </div>
                <div className="card-body pcardbody py-4 ">
                  <h4 className="fs-5 ">{product.name}</h4>
                  <p className=''>{product.discription}</p>
                  <h6 className="fw-bolder fs-4">Rs {product.sprice}</h6>
                  <div className="mt-3">
                    <Link to={`/medicinesdetails/${product.id}`}>
                      <button className=" text-decoration-none productbtn font-weight-bold">More Info</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {visibleCount < products.length && (
          <div className='text-center py-5'>
            <button onClick={showMoreItems} className="text-decoration-none py-2 px-5 productbtn font-weight-bold"> View More</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Medicines;
