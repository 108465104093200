import { PayloadAction } from "@reduxjs/toolkit";
import { put, take, takeLatest } from "redux-saga/effects";
import { signup, signin, success, failed } from '../slice/userSlice'
import { postRequest } from "./ApiInstence";
import { toast } from "react-toastify";
import {getProductAPI, getOneProductAPI} from "./product/productApi";
import { getProduct, getOneProduct } from "../slice/productSlice";
import { addContectUs, addAppointment } from "../slice/contactusSlice";
import { postContactUsAPI, postAppointmentAPI } from "./contactus/contactUsApi"

function* signinUser({ payload }) {
  try {
    const response = yield postRequest('customer/login', payload);
    console.log('i am called', payload)
    if (response?.status == 200) {
      yield put(success(response));
    } else {
      yield put(failed(response));
    }

  } catch (error) {
    yield put(failed(error));
  }
}

function* signupUser({ payload }) {

  try {

    const response = yield postRequest('customer', payload);
    if (response?.status === 200) {
      yield put(success(response?.data));
    } else {
      yield put(failed(response));
    }

  } catch (error) {
    console.log(error)
    toast('resposnse.message')
  }

}

// Generator function
export function* watchGetUser() {
  yield takeLatest(signin, signinUser);
  yield takeLatest(signup, signupUser);

  yield takeLatest(getProduct, getProductAPI)
  yield takeLatest(getOneProduct, getOneProductAPI)

  yield takeLatest(addContectUs, postContactUsAPI)
  yield takeLatest(addAppointment, postAppointmentAPI)


}