import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
const MyOrder = () => {
  const [orders, setOrders] = useState([
    {
      id: 1,
      orderDate: '2024-08-28',
  
      items: [
        { product: 'Product 1', price: 29.99, quantity: 2, image: 'https://via.placeholder.com/100' }
     
      ],
    },
    {
      id: 2,
      orderDate: '2024-08-27',
      
      items: [
        { product: 'Product 3', price: 19.99, quantity: 1, image: 'https://via.placeholder.com/100' }
    
      ],
    },
  ]);

  const calculateTotal = (items) => {
    return items.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  return (
    <div className="container my-5">
      <h2 className="pb-5 text-center aboutheading">Order History</h2>
      <div className='row justify-content-center align-items-center pl-lg-2 pl-2'>
        <div className='col-md-12'>
          {orders.map((order) => (
            <div key={order.id} className="mb-4">
              <div className="card myordercard">
                <div className="card-header myordercardheader d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Order ID: {order.id}</h5>
                 
                </div>
                <div className="card-body">
                  {order.items.map((item, index) => (
                    <div key={index} className="row mb-3 align-items-center">
                      <div className="col-md-3 col-4">
                        <img
                          src={item.image}
                          alt={item.product}
                          className="myorderpimg"
                        />
                      </div>
                      <div className="col-md-3 col-4">
                        <h6 className="mb-1">{item.product}</h6>
                      </div>
                      <div className="col-md-2 col-4">
                        <small className="">Quantity: {item.quantity}</small>
                      </div>
                      <div className="col-md-2 col-6 orderdate">
                        <small className="">Order Date: {order.orderDate}</small>
                      </div>
                      <div className="col-md-2 text-right col-6 orderdate">
                        <span className="font-weight-bold">Sub Total Rs{(item.price * item.quantity).toFixed(2)}</span>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="card-footer myordercardfooter text-right">
                  <h6 className="mb-0">Total: Rs{calculateTotal(order.items)}</h6>
                </div>
              </div>
              <hr className="dashed-hr" />
            </div>
          ))}
        </div>
      </div>
      <div className="text-center mt-5 pb-5">
        <Link to="/" className="checkout text-decoration-none">
          Continue Shopping
        </Link>
      </div>
    </div>
  );
};

export default MyOrder;