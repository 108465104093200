import React, { useState } from 'react';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>  
      <div className='faqbg'>
        <section id="faq" className="faq section light-background">
          <div className="container section-title text-center" data-aos="fade-up">
            <h2 className='aboutheading text-center'>Frequently Asked Questions</h2>
            <h6 className='text-center pt-2 pb-5'>
              Get answers to your most common questions about our therapies
            </h6>
          </div>
          <div className="container ">
            <div className="row pt-lg-5 leftspace justify-content-center">
              <div className="col-lg-10" data-aos="fade-up" data-aos-delay="100">
                <div className="faq-container">             
                  {faqData.map((faq, index) => (
                    <div
                      key={index}
                      className={`faq-item ${activeIndex === index ? 'faq-active' : ''}`}
                      onClick={() => handleToggle(index)}
                    >
                      <h3 className='fqaheading'>{faq.question}</h3>
                      <div className="faq-content" style={{ display: activeIndex === index ? 'block' : 'none' }}>
                        <p>{faq.answer}</p>
                      </div>
                      <i className={`faq-toggle ${activeIndex === index ? 'bi bi-chevron-down' : 'bi bi-chevron-right'}`}></i>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const faqData = [
  {
    question: "What is Abhyangam Therapy?",
    answer: "Abhyangam Therapy is a full-body massage with warm herbal oils, which enhances circulation, promotes detoxification, and deeply nourishes the skin."
  },
  {
    question: "How does Marma Therapy benefit the body?",
    answer: "Marma Therapy works on the vital points of the body to release blocked energy, improve circulation, and promote overall health."
  },
  {
    question: "What can I expect during Vibrator Marma Therapy?",
    answer: "Vibrator Marma Therapy involves gentle vibrations applied to Marma points, helping in the release of stress and improving energy flow."
  },
  {
    question: "Is Combo Marma Therapy suitable for everyone?",
    answer: "Combo Marma Therapy combines different techniques and is generally suitable for most people, though it is recommended to consult with a practitioner beforehand."
  },
  {
    question: "What are the benefits of Shirodhara Therapy?",
    answer: "Shirodhara Therapy involves pouring warm oil over the forehead, which helps to calm the mind, relieve stress, and improve sleep quality."
  },
  {
    question: "How often should I undergo Vasti Karma Therapy?",
    answer: "Vasti Karma Therapy, a specialized cleansing process, can be done periodically depending on individual health needs and a practitioner’s advice."
  }
];

export default Faq;
