import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialState = {
  user: null,
  response: null,
  loading: false,
  error: null,
};

export const contactUs = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {
    addContectUs: (state, payload) => {
      console.log('login stat ' + state.user, payload);
      state.loading = true;
    },
    addAppointment: (state, payload) => {
      console.log('login stat ' + state.user, payload);
      state.loading = true;
    },
    success: (state, {payload})=>{
        console.log('dddddddddddddddddddddddddddddddddddddddddd', payload)
        state.loading = false;
        state.response = payload
        return state
      },
      failed:(state)=>{
        state.loading = false;
        state.response = null;
      }
  },
})

// Action creators are generated for each case reducer function
export const { addContectUs, success, failed, addAppointment } = contactUs.actions

export default contactUs.reducer