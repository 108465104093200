import { createSlice } from '@reduxjs/toolkit'


const storedCartItems = localStorage.getItem('cartItems');

const initialState = storedCartItems ? JSON.parse(storedCartItems) : []

function saveCartToLocalStorage(products) {
  localStorage.setItem('cartItems', JSON.stringify(products));
}

export const cartSlice = createSlice({
  name: 'cartSliceName',
  initialState,
  reducers: {
    addProduct: (state, { payload }) => {
      console.log('payload', payload)
      const productExist = state.findIndex(item => item.id === payload.id);
      if (productExist !== -1) {
        state[productExist].qty++;
        state[productExist].totalPrice = state[productExist].qty * state[productExist].price;
      } else { state.push(payload) };
      saveCartToLocalStorage(state)
      return state 
    },
    removeProduct: (state, { payload }) => {
        console.log(payload)
        console.log(state)
      state = state.filter(item => item.id !== payload.id);
      saveCartToLocalStorage(state)
      return state
    },
    updateProduct: () => {

    },
    emptyCart: (state, action) => {
      state.value += action.payload
    },
    success: (state, {payload})=>{
      console.log(payload)
      state = payload
      return state
    },
    failed:()=>{

    }
  },
})

// Action creators are generated for each case reducer function
export const { addProduct, removeProduct, updateProduct, getProduct, success, failed, getOneProduct } = cartSlice.actions

export default cartSlice.reducer