// SingleCounter.jsx
import React, { useEffect, useState } from 'react';
import { FaUserMd, FaMapMarkerAlt, FaStethoscope, FaSmile } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';

const SingleCounter = ({ icon, target, duration, text }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const end = target;
    const incrementTime = Math.abs(Math.floor(duration / end));

    const timer = setInterval(() => {
      start += 1;
      setCount(start);

      if (start === end) {
        clearInterval(timer);
      }
    }, incrementTime);

    return () => clearInterval(timer); // Clear interval on component unmount
  }, [target, duration]);

  const renderIcon = () => {
    switch (icon) {
      case 'doctor':
        return <FaUserMd className="counter-icon" />;
      case 'hospital':
        return <FaMapMarkerAlt className="counter-icon" />;
      case 'beds':
        return <FaStethoscope className="counter-icon" />;
      case 'injections':
        return <FaSmile className="counter-icon" />;
      default:
        return null;
    }
  };

  return (
    <div className="counter text-center">
      {renderIcon()}
      <h2 className="timer count-title count-number">
        {count}
        <span className="plus-sign">+</span> {/* Add plus sign here */}
      </h2>
      <p className="count-text">{text}</p>
    </div>
  );
};

export default SingleCounter;
