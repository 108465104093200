import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Similarproduct = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        getProduct();
    }, []);

    const getProduct = () => {
        axios.get(process.env.REACT_APP_BACKEND_URL + 'product/random').then((response) => {
            if (response.data) {
                setProducts(response.data);
            }
        }).catch((error) => {
            console.error('Error fetching products:', error);
        });
    };

    return (
        <section className="pt-lg-5 pb-lg-5 ">
            <div className="container">
                <h3 className="mb-5 similarp">Similar Products</h3>
                <div className="row leftspace">
                    {products && products.map((product, index) => (
                        <div className="col-md-4 mb-3" key={index}>
                            <div className="product text-center">
                            <div className="position-relative product-hover">
                                <img className="product-image w-100 productcardbg  p-2" src={`${process.env.REACT_APP_BACKEND}uploads/${product.image}`} alt='product' />
                            </div>
                            <div className="card-body text-center pcardbody py-4">
                                <h4 className="fs-5">{product.name}</h4>
                                <h6 className="fw-bolder fs-4">{product.sprice} RS</h6>

                                <Link to={`/medicinesdetails/${product.id}`}>
                                    <button className=" text-decoration-none productbtn font-weight-bold">More Info</button>
                                </Link>
                            </div>
                        </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Similarproduct;
