import React from 'react'
import mission from '../../Assets/images/micon.png'
import vission from '../../Assets/images/vicon.png'
import value from '../../Assets/images/vvicon.png'
import microscop from '../../Assets/images/FaMicroscope.png';
import leaf from '../../Assets/images/FaLeaf.png'
import user from '../../Assets/images/FaUserMd.png'
import drpathakji from '../../Assets/images/pathakjidocumentory.jpeg'
import aboutbanner from '../../Assets/images/aboutbanner.png'
const About = () => {
  return (
    <div>
       <div class="border-0">
                <img className="bannerimges img-fluid" src={aboutbanner} alt="Card image" />   
      </div>
      <div className='container  py-5'>
        <div className='row my-lg-3 py-lg-5'>
          <h3 className='text-center pb-5 aboutheading'>About Dr. Pathak's Holistic Cure Khajuraho/Bhopal MP</h3>
          <div className='col-md-12'>

            <p className='text-justify aboutp'>A simple pain has power to distract your entire easiness and comfort of life, while a healing attention has a power to bring back your all lost powers, comfort and easiness in your life."

              When we know the reason of pain, we can treat it, and find cure for it, but when we get diverted through lots of messages and improper diagnosis, we get distracted and confused. With Dr.Pathak's Holistic Cure we are constantly working in field of diagnosis the exact reason of your ongoing sufferings and then offering the best available, less harmful and fast working medicinal formulas with our holistic approach in health Field.

              We respect all the methods of treatments from Nature to modern biochemistry, but utilise mostly the nature oriented formulas, therapies and treatments as per need of our patients.

              We work on single line concept - "Surgery is not the treatment of all pain".

              We are constantly trying to bring out the most easiest and active natural formulas and methods of treatments to serve human community around the globe.

              Late Dr.K.L.Pathak was the pioneer of Holistic approach of medicines in Khajuraho, in 1976. and now his son Dr.Raghav Pathak is leading at current all of his services with his wise and intellectual sharp knowledges in the same way as his Father after completion of his medical degree BHMS in 2008 and Diploma in Naturopathy and Yoga science in year of 2010.

              Dr.Raghav Pathak travelled around the globe with his expertises and helped innumerable people to find their health back with his knowledge and experience.

              If you are puzzled somewhere because of any kind of your health issues, you must feel free to call on our given numbers or click the button mentioned in page and reserve your appointment with Dr.Raghav Pathak sir, without single Hesitation.

              Dr.Raghav Says - "We serve with our knowledge and experiences but it's God who provide you final cure".

              "If the time of your sickness is nearby to end, then you will definitely meet a doctor who is there with your cure.</p>
          </div>
        </div>
        {/* vision mission value */}
        <div className="row">
          <section id="services" className="services section">
            <div className="container py-5 mb-5">
              <div className="row gy-4">
                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                  <div className="service-item missioncard text-start">
                    <div className="icon mb-3">
                      <img src={mission} alt='mission' className='img-fluid' />
                    </div>
                    <div>
                      <a href="#" className="stretched-link text-decoration-none">
                        <h3 className="mb-3">Our Mission</h3>
                      </a>
                      <p className='text-justify'>DPHC strives to provide excellent healthcare with compassion and dedication. Our mission is to improve community health through state-of-the-art treatments and a patient-centered approach in a supportive environment.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                  <div className="service-item visioncard text-start">
                    <div className="icon mb-3">
                    <img src={vission} alt='mission' className='' width={110} />
                    </div>
                    <div>
                      <a href="#" className="stretched-link text-decoration-none">
                        <h3 className="mb-3 text-white">Our Vision</h3>
                      </a>
                      <p className='text-justify text-white'>DPHC envisions becoming a top healthcare provider renowned for quality and innovation. We are committed to ensuring every patient receives exceptional care and support, paving the way for a healthier future.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                  <div className="service-item valuecard text-start">
                    <div className="icon mb-3">
                    <img src={value} alt='mission' className='img-fluid' />
                    </div>
                    <div>
                      <a href="#" className="stretched-link text-decoration-none">
                        <h3 className="mb-3">Our Value</h3>
                      </a>
                      <p className='text-justify'>At DPHC, we uphold integrity, compassion, and excellence. We are devoted to delivering respectful, empathetic care and maintaining the highest ethical standards in all our interactions.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* why choose section */}
        <section id="why-choose-us" className="about section py-lg-5 mb-5">
      <div className="container">
        <div className="row gy-4 gx-5">
          <div className="col-lg-6 position-relative align-self-center" data-aos="fade-up" data-aos-delay="200">
            <img src={drpathakji} className="shadow aboutvideo"  alt="About Us"  />
            <a href="https://youtu.be/hzv49Gp7-sE?si=3E8DRkFefmhOrQW0" className="glightbox pulsating-play-btn"></a>
          </div>
          
          <div className="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">
            <h3 className='aboutheading'>Why Choose Us</h3>
            <p className='text-justify aboutp'>A simple pain has the power to disrupt your life, but healing attention can restore your comfort and ease.</p>
            <ul>
              <li>
                <img src={microscop} alt='microscop' className='microscop'  width={40} />
                <div>
                  <h5 className='ml-3'>Expert Diagnosis</h5>
                  <p className='ml-3 text-justify aboutp'>We focus on identifying the exact cause of your suffering and provide the best available, least harmful, and fast-working medicinal formulas.</p>
                </div>
              </li>
              <li>
              <img src={leaf} alt='leaf' className='leaf ml-n3' width={65}  />
                <div className='me-2'>
                  <h5 className=''>Holistic Approach</h5>
                  <p className='text-justify aboutp'>We respect all treatment methods but prioritize nature-oriented formulas, therapies, and treatments to meet your needs.</p>
                </div>
              </li>
              <li>
              <img src={user} alt='user' className='user' width={35} />
                <div>
                  <h5 className='ml-3'>Experienced Leadership</h5>
                  <p className='ml-3 text-justify aboutp '>With roots dating back to 1976, Dr. Raghav Pathak continues the legacy of holistic medicine with extensive knowledge and experience.</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>




      </div>
    </div>
  )
}

export default About
