import { configureStore, applyMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from "redux-saga";
import counterSlice from '../slice/counterSlice';
import authSlice from '../slice/authSlice';
import rootSaga from '../apis/rootSaga';
import  productSlice from '../slice/productSlice';
import userSlice from '../slice/userSlice';
import cartSlice from '../slice/cartSlice';
import contactusSlice from '../slice/contactusSlice';

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: {
    counter : counterSlice,
    auth: authSlice,
    product: productSlice,
    user: userSlice,
    cart: cartSlice,
    contact: contactusSlice
  },
  middleware: ()=>[sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export default store;